import "./App.css";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import TrooperRegistrationForm from "./Components/RegisterTrooperForm";
import LoginTrooperForm from "./Components/LoginTrooperForm";
import CompanyRegistration from "./Components/CompanyRegistration";
import WorkAssignments from "./Components/WorkAssignment";
import TrooperAddWork from "./Components/TrooperAddWork";
import TrooperList from "./Components/TropperList";
import CompanyList from "./Components/CompanyList";
import WorkDashboard from "./Components/WorkDashboard";
import { Box } from "@mui/material";
import UnderConstruction from "./Components/UnderConstruction";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#000080",
    },
  },
});

function App() {
  const route = createBrowserRouter([
    {
      path: "/",
      element: <LoginTrooperForm />,
    },
    {
      path: "/register",
      element: <TrooperRegistrationForm />,
    },
    {
      path: "/companyRegistration",
      element: <CompanyRegistration />,
    },
    {
      path: "/workDashboard",
      element: <WorkDashboard />,
    },
    {
      path: "/addWork",
      element: <WorkAssignments />,
    },
    {
      path: "/trooperList",
      element: <TrooperList />,
      },
      {
          path: "/companyList",
          element: <CompanyList />,
      },
    {
      path: "/underConstruction",
      element: <UnderConstruction />,
    },
  ]);
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={route}></RouterProvider>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
