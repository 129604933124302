import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Paper,
  Box,
  Snackbar,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ResponsiveAppBar from "./ResponsiveAppBar";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required("Company name is required"),
  contactPersonName: Yup.string().required("Contact person name is required"),
  contactPersonTitle: Yup.string().required("Contact person title is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zipCode: Yup.string()
    .required("Zip code is required")
    .matches(/^\d{5}(-\d{4})?$/, "Invalid zip code"),
  contactPersonEmailAddress: Yup.string()
    .email("Invalid email")
    .required("Contact person email is required"),
  contactPersonPhone: Yup.string()
    .required("Contact person phone number is required")
    .matches(/^\d{10}$/, "Contact person phone number must be 10 digits"),
});

const WorkAssignments = () => {
  const initialValues = {
    companyName: "",
    companyLocation: "",
    shift: "",
    payRate: "",
    numberNeeded: "",
  };

  const [loading, setLoading] = React.useState(false);
  const [loadingMenu, setloadingMenu] = React.useState(false);

  const [companyNames, setCompanyNames] = React.useState([]);
  const [companyRates, setCompanyRates] = React.useState([]);
  const [companyShifts, setCompanyShifts] = React.useState([]);
  const [companyLocations, setCompanyLocations] = React.useState([]);

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setLoading(true);
    try {
      //Add API Path....
      const endPoint = process.env.REGISTER_COMPANY_API;
      const response = await axios
        .post(endPoint, values, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          console.log(response.data);
          setSnackbar({
            open: true,
            message: "Company Registration successful!",
            severity: "success",
          });
          resetForm();
        })
        .catch((error) => {
          setSnackbar({
            open: true,
            message: "Error submitting form. Please try again.",
            severity: "error",
          });
          console.error("There was an error!", error);
        });
    } catch (error) {
      console.error("Error submitting form:", error);
      setSnackbar({
        open: true,
        message: "Error submitting form. Please try again.",
        severity: "error",
      });
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    const fetchStates = async () => {
      setLoading(true);
      const endPoint = process.env.REACT_APP_GET_WORK_DETAILS_API;
      try {
        const response = await fetch(endPoint);
        const data = await response.json();
        const companyList = data.companies.map((company) => ({
          id: company.id,
          name: company.data,
        }));
        const rateList = data.rates.map((rate) => ({
          id: rate.id,
          name: rate.data,
        }));
        const shiftsList = data.shifts.map((shift) => ({
          id: shift.id,
          name: shift.data,
        }));
        const locationsList = data.locations.map((location) => ({
          id: location.id,
          name: location.data,
        }));
        setCompanyNames(companyList);
        setCompanyRates(rateList);
        setCompanyShifts(shiftsList);
        setCompanyLocations(locationsList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching states:", error);
        setLoading(false);
      }
    };

    fetchStates();
  }, []);

  const numberNeededs = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
    { id: 9, name: "9" },
    { id: 10, name: "10" },
  ];
  const shifts = [];

  return loading ? (
    <CircularProgress size={20} color="inherit" />
  ) : (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ResponsiveAppBar></ResponsiveAppBar>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          background: "linear-gradient(180deg, #000080 30%, #000000 90%)",
        }}
      >
        <Grid
          container
          sx={{ justifyContent: "center", alignContent: "center" }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            sx={{ justifyContent: "center", alignContent: "center" }}
          >
            <Paper
              elevation={6}
              sx={{
                p: 4,
                m: 4,
                mt: 10,
                borderRadius: "16px",
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                sx={{ pb: 2 }}
              >
                Add New Work Assignments
              </Typography>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          error={touched.companyName && errors.companyName}
                        >
                          <InputLabel>Company</InputLabel>
                          <Field
                            as={Select}
                            name="companyName"
                            label="Company"
                            error={touched.companyName && errors.companyName}
                          >
                            {companyNames.map((companyName) => (
                              <MenuItem
                                key={companyName.id}
                                value={companyName.name}
                              >
                                {companyName.name}
                              </MenuItem>
                            ))}
                          </Field>
                          <FormHelperText>
                            {touched.companyName && errors.companyName}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          error={
                            touched.companyLocation && errors.companyLocation
                          }
                        >
                          <InputLabel>Work Location</InputLabel>
                          <Field
                            as={Select}
                            name="companyLocation"
                            label="Work Location"
                            error={
                              touched.companyLocation && errors.companyLocation
                            }
                          >
                            {companyLocations.map((companyLocation) => (
                              <MenuItem
                                key={companyLocation.id}
                                value={companyLocation.name}
                              >
                                {companyLocation.name}
                              </MenuItem>
                            ))}
                          </Field>
                          <FormHelperText>
                            {touched.companyLocation && errors.companyLocation}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          error={touched.startDate && errors.startDate}
                        >
                          <Field
                            as={DatePicker}
                            fullWidth
                            label="Start Date"
                            name="startDate"
                            error={touched.startDate && errors.startDate}
                            helperText={touched.startDate && errors.startDate}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          error={touched.endDate && errors.endDate}
                        >
                          <Field
                            as={DatePicker}
                            label="End Date"
                            name="endDate"
                            error={touched.endDate && errors.endDate}
                            helperText={touched.endDate && errors.endDate}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          error={touched.shift && errors.shift}
                        >
                          <InputLabel>Shift</InputLabel>
                          <Field
                            as={Select}
                            name="shift"
                            label="Shift"
                            error={touched.shift && errors.shift}
                          >
                            {companyShifts.map((shift) => (
                              <MenuItem key={shift.id} value={shift.name}>
                                {shift.name}
                              </MenuItem>
                            ))}
                          </Field>
                          <FormHelperText>
                            {touched.shift && errors.shift}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          error={touched.payRate && errors.payRate}
                        >
                          <InputLabel>Pay Rate</InputLabel>
                          <Field
                            as={Select}
                            name="payRate"
                            label="Pay Rate"
                            error={touched.payRate && errors.payRate}
                          >
                            {companyRates.map((payRate) => (
                              <MenuItem key={payRate.id} value={payRate.name}>
                                {payRate.name}
                              </MenuItem>
                            ))}
                          </Field>
                          <FormHelperText>
                            {touched.payRate && errors.payRate}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          error={touched.numberNeeded && errors.numberNeeded}
                        >
                          <InputLabel>Number Needed</InputLabel>
                          <Field
                            as={Select}
                            name="numberNeeded"
                            label="Number Needed"
                            error={touched.numberNeeded && errors.numberNeeded}
                          >
                            {numberNeededs.map((numberNeeded) => (
                              <MenuItem
                                key={numberNeeded.id}
                                value={numberNeeded.name}
                              >
                                {numberNeeded.name}
                              </MenuItem>
                            ))}
                          </Field>
                          <FormHelperText>
                            {touched.numberNeeded && errors.numberNeeded}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          fullWidth
                          size="large"
                          disabled={isSubmitting || loading}
                          startIcon={
                            loading ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : null
                          }
                        >
                          Submit New Work Assignment
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </LocalizationProvider>
  );
};

export default WorkAssignments;
