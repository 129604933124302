import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
} from "@mui/material";
import { AssignmentTurnedIn, Add, HourglassEmpty } from "@mui/icons-material";
import ResponsiveAppBar from "./ResponsiveAppBar";

import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";

const WorkDashboard = () => {
  const [loading, setLoading] = React.useState(false);
  const userData = useSelector((state) => state.user);
  const [availableWork, setAvailableWork] = useState([]);

  useEffect(() => {
    fetchAvailableWork();
  }, []);

  const fetchAvailableWork = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://az-208conciergefunc.azurewebsites.net/api/Jobs?code=eu1Kuwd_nvv5ZCQ9q3WHe1-0V4-_H2pNL_6XHvu7_wQQAzFux-E70Q%3D%3D"
      );
      setAvailableWork(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching available work data:", error);
    }
  };

  const WorkCard = ({ work, type }) => (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} sx={{ bgcolor: "black", px: 5 }}>
        <Card sx={{ mb: 2, pb: 0, bgcolor: "rgba(255, 255, 255, 0.4)" }}>
          <CardContent>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <AssignmentReturnIcon
                fontSize="large"
                sx={{ color: "#ffffff", height: 70, width: 70 }}
              />
              <Box
                display={"flex"}
                flexDirection={"column"}
                textAlign={"left"}
                px={2}
              >
                <Typography variant="body2" color="white">
                  {work.companyName}
                </Typography>
                <Typography variant="body2" color="white">
                  {work.shift}
                </Typography>
                <Typography variant="body2" color="white">
                  {work.location}
                </Typography>
                <Typography variant="body2" color="white">
                  {work.rate}
                </Typography>
                <Typography variant="body2" color="white">
                  {work.jobDate}
                </Typography>
              </Box>
              <Button
                variant="contained"
                color={type === "current" ? "success" : "error"}
                size="large"
                height="inherit"
              >
                {type === "current"
                  ? work.status === "CONFIRM"
                    ? "CONFIRM"
                    : "IN PROGRESS"
                  : "ADD"}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} sx={{ bgcolor: "black", px: 5 }}>
        <Card
          sx={{
            mb: 2,
            pb: 0,
            height: "inherit",
            bgcolor: "rgba(255, 255, 255, 0.4)",
          }}
        >
          <CardContent>
            <Typography variant="h4" align="center" color="white">
              {work.numberNeeded}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  if (!userData) {
    return <Typography>Loading...</Typography>;
  }

  return loading ? (
    <CircularProgress size={20} color="inherit" />
  ) : (
    <>
      <ResponsiveAppBar></ResponsiveAppBar>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          background: "linear-gradient(180deg, #000080 30%, #000000 90%)",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 2,
          p: 10,
        }}
      >
        <Typography variant="h4" align="center" color="white" gutterBotom>
          {userData.userFName + " " + userData.userLName}
        </Typography>
        <Typography variant="body1" align="center" color="white" gutterBottom>
          {userData.address}
        </Typography>
        <Typography variant="body1" align="center" color="white" gutterBottom>
          {userData.phone}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} sx={{ bgcolor: "black", px: 5 }}>
            <Typography variant="h6" color="white" gutterBottom>
              Current Work
            </Typography>
            {/* {userData.currentWork.map((work, index) => (
            <WorkCard key={index} work={work} type="current" />
          ))} */}
          </Grid>
          <Grid item xs={12} md={8} sx={{ bgcolor: "black", px: 5 }}>
            <Typography variant="h6" color="white" gutterBottom>
              Available New Work
            </Typography>
            {availableWork.map((work, index) => (
              <WorkCard key={index} work={work} type="available" />
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default WorkDashboard;
