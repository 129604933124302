import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUser } from "../Redux/userSlice";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Box,
  InputAdornment,
  IconButton,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginTrooperForm = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [loading, setLoading] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const initialValues = {
    emailAddress: "",
    password: "",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setLoading(true);
    try {
      const endPoint = process.env.REACT_APP_LOGIN_API;
      const response = await axios
        .post(endPoint, values, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          console.log(response.data);
          dispatch(setUser(response.data));
          setSnackbar({
            open: true,
            message: "Login successful!",
            severity: "success",
          });
          resetForm();
          navigate("/underConstruction");
        })
        .catch((error) => {
          setSnackbar({
            open: true,
            message:
              "Login failed! Email Address not found or incorrect Password entered!",
            severity: "error",
          });
          console.error("There was an error!", error);
        });
    } catch (error) {
      console.error("Error submitting form:", error);
      setSnackbar({
        open: true,
        message: "Error submitting form. Please try again.",
        severity: "error",
      });
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          background: "linear-gradient(180deg, #000080 30%, #000000 90%)",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Paper
              elevation={6}
              sx={{
                p: 4,
                borderRadius: "16px",
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 200,
                  width: 200,
                  p: 2,
                }}
                alt="logo"
                src="logo.jpg"
              />
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                align="center"
              >
                Login to 208 Consierge Off-Duty Work Scheduler
              </Typography>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="emailAddress"
                          label="Email Address"
                          type="email"
                          error={touched.emailAddress && errors.emailAddress}
                          helperText={
                            touched.emailAddress && errors.emailAddress
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ textAlign: "right" }}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="password"
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          error={touched.password && errors.password}
                          helperText={touched.password && errors.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Link
                          to="/register"
                          type="submit"
                          class="btn btn-primary"
                        >
                          Forgot pssword?
                        </Link>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          fullWidth
                          size="large"
                          disabled={isSubmitting || loading}
                          startIcon={
                            loading ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : null
                          }
                        >
                          Login
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
              <Typography variant="p" component="p" gutterBottom sx={{ py: 2 }}>
                Don't have an account?{" "}
                <Link to="/register" type="submit" class="btn btn-primary">
                  <b>Register</b>
                </Link>
                .
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoginTrooperForm;
