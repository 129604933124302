import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Paper,
  Box,
  Snackbar,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import ResponsiveAppBar from "./ResponsiveAppBar";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Company name is required"),
  address: Yup.string().required("Address is required"),
  county: Yup.string().required("County is required"),
  primaryContactName: Yup.string().required(
    "Primary contact name is required"
  ),
  primaryContactEmailAddress: Yup.string()
    .email("Invalid email")
    .required("Primary contact email is required"),
  primaryContactPhone: Yup.string()
    .required("Primary contact phone number is required")
    .matches(/^\d{10}$/, "Primary contact phone number must be 10 digits"),
  secondaryContactName: Yup.string().required(
    "Secondary contact name is required"
  ),
  secondaryContactEmailAddress: Yup.string()
    .email("Invalid email")
    .required("Secondary contact email is required"),
  secondaryContactPhone: Yup.string()
    .required("Secondary contact phone number is required")
    .matches(/^\d{10}$/, "Secondary contact phone number must be 10 digits"),
  accountantName: Yup.string().required("Accountant name is required"),
  accountantEmailAddress: Yup.string()
    .email("Invalid email")
    .required("Accountant email is required"),
  accountantPhone: Yup.string()
    .required("Accountant phone number is required")
    .matches(/^\d{10}$/, "Accountant phone number must be 10 digits"),
});

const CompanyRegistration = () => {
  const initialValues = {
    name: "",
    address: "",
    county: "",
    primaryContactName: "",
    primaryContactEmailAddress: "",
    primaryContactPhone: "",
    secondaryContactName: "",
    secondaryContactEmailAddress: "",
    secondaryContactPhone: "",
    accountantName: "",
    accountantEmailAddress: "",
    accountantPhone: "",
  };

  const [loading, setLoading] = React.useState(false);
  const [loadingMenu, setloadingMenu] = React.useState(false);

  const [states, setStates] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState("");

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
      setLoading(true);

      console.log(values);

    try {
      //Add API Path....
        const endPoint = process.env.REGISTER_COMPANY_API ??
      
        "https://az-208conciergefunc.azurewebsites.net/api/CompanyCreate?code=a1yZ_b4AiVMRs55nouwAvDXRMMV4dL-AqoyDt4l0jHTiAzFuuf_U1Q%3D%3D"
        ;
      const response = await axios
        .post(endPoint, values, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          console.log(response.data);
          setSnackbar({
            open: true,
            message: "Company Registration successful!",
            severity: "success",
          });
          resetForm();
        })
        .catch((error) => {
          setSnackbar({
            open: true,
            message: "Error submitting form. Please try again.",
            severity: "error",
          });
          console.error("There was an error!", error);
        });
    } catch (error) {
      console.error("Error submitting form:", error);
      setSnackbar({
        open: true,
        message: "Error submitting form. Please try again.",
        severity: "error",
      });
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  return (
    <>
      <ResponsiveAppBar></ResponsiveAppBar>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          background: "linear-gradient(180deg, #000080 30%, #000000 90%)",
        }}
      >
        <Grid
          container
          sx={{ justifyContent: "center", alignContent: "center" }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            sx={{ justifyContent: "center", alignContent: "center" }}
          >
            <Paper
              elevation={6}
              sx={{
                p: 4,
                m: 4,
                mt: 10,
                borderRadius: "16px",
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                sx={{ pb: 2 }}
              >
                Company Registration
              </Typography>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="name"
                          label="Company Name"
                          error={touched.name && errors.name}
                          helperText={touched.name && errors.name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="address"
                          label="Address"
                          error={touched.address && errors.address}
                          helperText={touched.address && errors.address}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="county"
                          label="County"
                          error={touched.county && errors.county}
                          helperText={touched.county && errors.county}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="primaryContactName"
                          label="Primary Contact Name"
                          error={
                            touched.primaryContactName &&
                            errors.primaryContactName
                          }
                          helperText={
                            touched.primaryContactName &&
                            errors.primaryContactName
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="primaryContactEmailAddress"
                          label="Primary Contact Email Address"
                          type="email"
                          error={
                            touched.primaryContactEmailAddress &&
                            errors.primaryContactEmailAddress
                          }
                          helperText={
                            touched.primaryContactEmailAddress &&
                            errors.primaryContactEmailAddress
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="primaryContactPhone"
                          label="Primary Contact Phone"
                          error={
                            touched.primaryContactPhone &&
                            errors.primaryContactPhone
                          }
                          helperText={
                            touched.primaryContactPhone &&
                            errors.primaryContactPhone
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="secondaryContactName"
                          label="Secondary Contact Name"
                          error={
                            touched.secondaryContactName &&
                            errors.secondaryContactName
                          }
                          helperText={
                            touched.secondaryContactName &&
                            errors.secondaryContactName
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="secondaryContactEmailAddress"
                          label="Secondary Contact Email Address"
                          type="email"
                          error={
                            touched.secondaryContactEmailAddress &&
                            errors.secondaryContactEmailAddress
                          }
                          helperText={
                            touched.secondaryContactEmailAddress &&
                            errors.secondaryContactEmailAddress
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="secondaryContactPhone"
                          label="Secondary Contact Phone"
                          error={
                            touched.secondaryContactPhone &&
                            errors.secondaryContactPhone
                          }
                          helperText={
                            touched.secondaryContactPhone &&
                            errors.secondaryContactPhone
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="accountantName"
                          label="Accountant Name"
                          error={
                            touched.accountantName && errors.accountantName
                          }
                          helperText={
                            touched.accountantName && errors.accountantName
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="accountantEmailAddress"
                          label="Accountant Email Address"
                          type="email"
                          error={
                            touched.accountantEmailAddress &&
                            errors.accountantEmailAddress
                          }
                          helperText={
                            touched.accountantEmailAddress &&
                            errors.accountantEmailAddress
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="accountantPhone"
                          label="Accountant Phone"
                          error={
                            touched.accountantPhone && errors.accountantPhone
                          }
                          helperText={
                            touched.accountantPhone && errors.accountantPhone
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          fullWidth
                          size="large"
                          disabled={isSubmitting || loading}
                          startIcon={
                            loading ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : null
                          }
                        >
                          Register Company
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CompanyRegistration;
