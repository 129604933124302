import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ResponsiveAppBar from "./ResponsiveAppBar";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PersonIcon from "@mui/icons-material/Person";

const TropperList = () => {
  const [loading, setLoading] = React.useState(false);
  const [troopers, setTroopers] = useState([]);
  const [editingTrooper, setEditingTrooper] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchTroopers();
  }, []);

  const fetchTroopers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://az-208conciergefunc.azurewebsites.net/api/TroopersReportFunc?code=NWIYnhGAthryzI0sPsJ7inesaYkMv2Z-uu8XCTA3RdXMAzFuiHm_PQ%3D%3D"
        );
        setTroopers(response.data);
        console.log(response.data);
    setLoading(false);
    } catch (error) {
      console.error("Error fetching troopers:", error);
    }
  };

  const handleEdit = (trooper) => {
    setEditingTrooper(trooper);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
        await axios.delete(`https://az-208conciergefunc.azurewebsites.net/api/trooperdelete/${id}`);
      fetchTroopers();
    } catch (error) {
      console.error("Error deleting trooper:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEditingTrooper(null);
  };

  const handleSave = async () => {
    try {
      await axios.put(`YOUR_API_ENDPOINT/${editingTrooper.id}`, editingTrooper);
      fetchTroopers();
      handleClose();
    } catch (error) {
      console.error("Error updating trooper:", error);
    }
  };

  const handleChange = (e) => {
    setEditingTrooper({
      ...editingTrooper,
      [e.target.name]: e.target.value,
    });
  };
  return loading ? (
    <CircularProgress size={20} color="inherit" />
  ) : (
    <>
      <ResponsiveAppBar></ResponsiveAppBar>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          background: "linear-gradient(180deg, #000080 30%, #000000 90%)",
        }}
      >
        <TableContainer
          component={Box}
          className="max-h-70vh overflow-auto"
          sx={{
            mt: 2,
            p: 10,
            textAlign: "left",
          }}
        >
          <Typography
            variant="h5"
            component="h5"
            color="white"
            gutterBottom
            sx={{ pb: 2 }}
          >
            Trooper List
          </Typography>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell>
                  <b>Business Name</b>
                </TableCell>
                <TableCell>
                  <b>Perm</b>
                </TableCell>
                <TableCell>
                  <b>Email</b>
                </TableCell>
                <TableCell>
                  <b>Contact</b>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {troopers.map((trooper) => (
                <TableRow key={trooper.id} sx={{ backgroundColor: "black" }}>
                  <TableCell sx={{ color: "white" }}>
                    <PersonIcon />
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                    }}
                  >
                    {trooper.name}
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    {trooper.businessName}
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>{trooper.perm}</TableCell>
                  <TableCell sx={{ color: "white" }}>{trooper.email}</TableCell>
                  <TableCell sx={{ color: "white" }}>
                    {trooper.contact}
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    <EditNoteIcon
                      sx={{ pr: 4 }}
                      onClick={() => handleEdit(trooper)}
                    />
                    <DeleteForeverIcon
                      onClick={() => handleDelete(trooper.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit Trooper</DialogTitle>
          <DialogContent>
            <TextField
              name="name"
              label="Name"
              value={editingTrooper?.name || ""}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="businessName"
              label="Business Name"
              value={editingTrooper?.businessName || ""}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="perm"
              label="Perm"
              value={editingTrooper?.perm || ""}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="email"
              label="Email"
              value={editingTrooper?.email || ""}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="contact"
              label="Contact"
              value={editingTrooper?.contact || ""}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default TropperList;
