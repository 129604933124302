import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ResponsiveAppBar from "./ResponsiveAppBar";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PersonIcon from "@mui/icons-material/Person";

const CompanyList = () => {
  const [loading, setLoading] = React.useState(false);
  const [companies, setCompanies] = useState([]);
    const [editingCompany, setEditingCompany] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
      fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://az-208conciergefunc.azurewebsites.net/api/TroopersReportFunc?code=NWIYnhGAthryzI0sPsJ7inesaYkMv2Z-uu8XCTA3RdXMAzFuiHm_PQ%3D%3D"
      );
    setCompanies(response.data);
    setLoading(false);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

    const handleEdit = (company) => {
        setEditingCompany(company);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`YOUR_API_ENDPOINT/${id}`);
      fetchCompanies();
    } catch (error) {
      console.error("Error deleting company:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEditingCompany(null);
  };

  const handleSave = async () => {
    try {
      await axios.put(`YOUR_API_ENDPOINT/${editingCompany.id}`, editingCompany);
      fetchCompanies();
      handleClose();
    } catch (error) {
      console.error("Error updating company:", error);
    }
  };

  const handleChange = (e) => {
      setEditingCompany({
          ...editingCompany,
      [e.target.name]: e.target.value,
    });
  };
  return loading ? (
    <CircularProgress size={20} color="inherit" />
  ) : (
    <>
      <ResponsiveAppBar></ResponsiveAppBar>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          background: "linear-gradient(180deg, #000080 30%, #000000 90%)",
        }}
      >
        <TableContainer
          component={Box}
          className="max-h-70vh overflow-auto"
          sx={{
            mt: 2,
            p: 10,
            textAlign: "left",
          }}
        >
          <Typography
            variant="h5"
            component="h5"
            color="white"
            gutterBottom
            sx={{ pb: 2 }}
          >
            Company List
          </Typography>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell>
                    <b>Address</b>
                </TableCell>
                <TableCell>
                  <b>Primary Contact</b>
                </TableCell>
                <TableCell>
                  <b>Secondary Contact</b>
                </TableCell>
                <TableCell>
                  <b>Accountant</b>
                </TableCell>
                <TableCell>
                  <b>Contact</b>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                              {companies.map((company) => (
                                  <TableRow key={company.id} sx={{ backgroundColor: "black" }}>

                  <TableCell
                    sx={{
                      color: "white",
                    }}
                  >
                    {company.name}
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    {company.address}
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>{company.primaryContact}</TableCell>
                  <TableCell sx={{ color: "white" }}>{company.secondaryContact}</TableCell>
                  <TableCell sx={{ color: "white" }}>
                    {company.accountant}
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    <EditNoteIcon
                      sx={{ pr: 4 }}
                      onClick={() => handleEdit(company)}
                    />
                    <DeleteForeverIcon
                      onClick={() => handleDelete(company.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit Company</DialogTitle>
          <DialogContent>
            <TextField
              name="name"
              label="Name"
              value={editingCompany?.name || ""}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="address"
              label="Address"
                              value={editingCompany?.address || ""}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="primaryContact"
                              label="Primary Contact"
                              value={editingCompany?.primaryContact || ""}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="secondaryContact"
                              label="Secondary Contact"
                              value={editingCompany?.secondaryContact || ""}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="accountant"
              label="Accountant"
              value={editingCompany?.accountant || ""}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default CompanyList;
