import React from "react";
import ResponsiveAppBar from "./ResponsiveAppBar";
import { Box, Typography } from "@mui/material";

const UnderConstruction = () => {
  return (
    <>
      <ResponsiveAppBar></ResponsiveAppBar>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          background: "linear-gradient(180deg, #000080 30%, #000000 90%)",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          align="center"
          color="white"
        >
          This page is currently under construction.
          <br />
          Please check back later.
        </Typography>
      </Box>
    </>
  );
};

export default UnderConstruction;
