import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Paper,
  Box,
  InputAdornment,
  IconButton,
  Snackbar,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-zA-Z]/, "Password must contain at least one letter")
    .matches(/[0-9]/, "Password must contain at least one number"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  businessName: Yup.string().required("Business name is required"),
  permId: Yup.string()
    .required("Perm ID is required")
    .matches(/^\d{4}$/, "Perm ID must be 4 digits"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  county: Yup.string().required("County is required"),
  state: Yup.string().required("State is required"),
  zipCode: Yup.string()
    .required("Zip code is required")
    .matches(/^\d{5}(-\d{4})?$/, "Invalid zip code"),
  emailAddress: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10}$/, "Phone number must be 10 digits"),
});

const RegisterTrooperForm = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    businessName: "",
    permId: "",
    address: "",
    city: "",
    county: "",
    state: "FL",
    zipCode: "",
    emailAddress: "",
    phoneNumber: "",
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const [loading, setLoading] = React.useState(false);
  const [loadingMenu, setloadingMenu] = React.useState(false);

  const [states, setStates] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState("");

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const navigate = useNavigate();

  /* React.useEffect(() => {
    const fetchStates = async () => {
      setloadingMenu(true);
      const endPoint = process.env.REACT_APP_GET_STATES_API;
      try {
        const response = await fetch(endPoint);
        const data = await response.json();
        const stateList = data.map((state) => ({
          name: state.description,
          code: state.code,
          id: state.stateId,
        }));
        setStates(stateList);
        setloadingMenu(false);
      } catch (error) {
        console.error("Error fetching states:", error);
        setloadingMenu(false);
      }
    };

    fetchStates();
  }, []); */

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setLoading(true);
    try {
      const endPoint = process.env.REACT_APP_REGISTER_API;
      const response = await axios
        .post(endPoint, values, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          console.log(response.data);
          setSnackbar({
            open: true,
            message: "Registration successful!",
            severity: "success",
          });
          resetForm();
          navigate("/");
        })
        .catch((error) => {
          setSnackbar({
            open: true,
            message: "Error submitting form. Please try again.",
            severity: "error",
          });
          console.error("There was an error!", error);
        });
    } catch (error) {
      console.error("Error submitting form:", error);
      setSnackbar({
        open: true,
        message: "Error submitting form. Please try again.",
        severity: "error",
      });
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          background: "linear-gradient(180deg, #000080 30%, #000000 90%)",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: 4,
              color: "white",
            }}
          >
            <Box
              component="img"
              sx={{
                height: 200,
                width: 200,
              }}
              alt="logo"
              src="logo.jpg"
            />
            <Typography variant="h4" component="h1" gutterBottom sx={{ py: 4 }}>
              Welcome to 208 Consierge Off-Duty Work Scheduler
            </Typography>
            <Link to="/" type="submit" class="btn btn-primary">
              <Button variant="contained" color="secondary">
                Login
              </Button>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            sx={{ justifyContent: "center", alignContent: "center" }}
          >
            <Paper
              elevation={6}
              sx={{
                p: 4,
                m: 4,
                borderRadius: "16px",
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                sx={{ pb: 2 }}
              >
                Register Trooper
              </Typography>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="firstName"
                          label="First Name"
                          error={touched.firstName && errors.firstName}
                          helperText={touched.firstName && errors.firstName}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="lastName"
                          label="Last Name"
                          error={touched.lastName && errors.lastName}
                          helperText={touched.lastName && errors.lastName}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="password"
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          error={touched.password && errors.password}
                          helperText={touched.password && errors.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="confirmPassword"
                          label="Confirm Password"
                          type={showConfirmPassword ? "text" : "password"}
                          error={
                            touched.confirmPassword && errors.confirmPassword
                          }
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                          /* InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }} */
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="businessName"
                          label="Business Name"
                          error={touched.businessName && errors.businessName}
                          helperText={
                            touched.businessName && errors.businessName
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="permId"
                          label="Perm ID #"
                          error={touched.permId && errors.permId}
                          helperText={touched.permId && errors.permId}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="address"
                          label="Address"
                          error={touched.address && errors.address}
                          helperText={touched.address && errors.address}
                        />
                      </Grid>
                      <Grid container xs={12} sm={6} sx={{ pt: 2, pl: 2 }}>
                        <Grid item xs={6} sm={6} sx={{ pr: 1 }}>
                          <Field
                            as={TextField}
                            name="city"
                            label="City"
                            error={touched.city && errors.city}
                            helperText={touched.city && errors.city}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} sx={{ pl: 1 }}>
                          <Field
                            as={TextField}
                            name="county"
                            label="County"
                            error={touched.county && errors.county}
                            helperText={touched.county && errors.county}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          error={touched.state && errors.state}
                        >
                          <InputLabel>State</InputLabel>
                          <Field
                            as={Select}
                            name="state"
                            label="State"
                            defaultValue={"FL"}
                            error={touched.state && errors.state}
                          >
                            <MenuItem value={"FL"}>FL</MenuItem>
                          </Field>
                          <FormHelperText>
                            {touched.state && errors.state}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="zipCode"
                          label="Zip Code"
                          error={touched.zipCode && errors.zipCode}
                          helperText={touched.zipCode && errors.zipCode}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="emailAddress"
                          label="Email Address"
                          type="email"
                          error={touched.emailAddress && errors.emailAddress}
                          helperText={
                            touched.emailAddress && errors.emailAddress
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="phoneNumber"
                          label="Phone Number"
                          error={touched.phoneNumber && errors.phoneNumber}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          fullWidth
                          size="large"
                          disabled={isSubmitting || loading}
                          startIcon={
                            loading ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : null
                          }
                        >
                          Register Trooper
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RegisterTrooperForm;
